import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-deadlock/api.mjs";
import type { ProfileHeroStats } from "@/game-deadlock/models/profileHeroStats.mjs";
import { ProfileHeroStatsValidator } from "@/game-deadlock/models/profileHeroStats.mjs";
import { devWarn } from "@/util/dev.mjs";

const EXPIRY = 1000 * 60 * 10; // 10 minutes

export default function getProfileHeroStats(props: {
  steamId: string;
}): Promise<ProfileHeroStats | undefined> {
  if (!props?.steamId) return;
  try {
    return getData(
      API.getProfileHeroStats(props),
      ProfileHeroStatsValidator,
      ["deadlock", "profileHeroStats", props.steamId],
      { expiryTime: EXPIRY },
    );
  } catch (e) {
    devWarn(
      `Deadlock: Failed to fetch player hero stats with steam id: ${props.steamId}`,
      e,
    );
  }
}
