import type { RouteState } from "@/__main__/router.mjs";
import {
  getMatchlist,
  getMatchlistMeta,
} from "@/game-deadlock/fetches/matchlist.mjs";
import getProfileHeroStats from "@/game-deadlock/fetches/profile-hero-stats.mjs";
import getProfileStats from "@/game-deadlock/fetches/profile-stats.mjs";
import getSteam from "@/game-deadlock/fetches/steam.mjs";
import { setProfileByMatchlistMeta } from "@/game-deadlock/utils/actions.mjs";
import { steam3to64 } from "@/util/steam.mjs";

export default async function fetchData(
  [steamId]: FixedLengthArray<string, 2>,
  searchParam: URLSearchParams,
  state: RouteState,
): Promise<void> {
  await Promise.all(
    (
      Object.entries(state.transient?.visibleMatches ?? {})
        .filter(([_, isVisible]) => isVisible)
        .map(([matchId]) =>
          getMatchlistMeta({
            steamId,
            matchId,
          }),
        ) as P
    ).concat([
      getSteam({
        steamId3: steamId,
        steamId64: steam3to64(Number(steamId)),
      }),
      getProfileHeroStats({ steamId }),
      getProfileStats({ steamId }),
      (async () => {
        const matchlist = await getMatchlist({ steamId });
        const latestMatchId = matchlist?.[0];
        if (!latestMatchId) return;
        setProfileByMatchlistMeta(
          await getMatchlistMeta({ steamId, matchId: latestMatchId }),
        );
      })(),
    ] as P),
  );
}

type P = Array<Promise<unknown>>;
