import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-deadlock/api.mjs";
import type {
  Matchlist,
  MatchlistMeta,
} from "@/game-deadlock/models/matchlist.mjs";
import {
  MatchlistMetaValidator,
  MatchlistValidator,
} from "@/game-deadlock/models/matchlist.mjs";
import { devWarn } from "@/util/dev.mjs";

export async function getMatchlist(props: {
  steamId: string;
}): Promise<Matchlist | undefined> {
  if (!props?.steamId) return;
  try {
    const matchlist = await getData(
      API.getMatchlist(props),
      MatchlistValidator,
      ["deadlock", "matchlist", props.steamId],
    );
    return matchlist;
  } catch (e) {
    devWarn(
      `Deadlock: Failed to fetch matchlist for steam id: ${props.steamId}`,
      e,
    );
  }
}

/**
 * Matchlist Meta is just a smaller response payload of a full match with only the local player's data
 */
export async function getMatchlistMeta(props: {
  steamId: string;
  matchId: string;
}): Promise<MatchlistMeta | undefined> {
  if (!props?.steamId || !props?.matchId) return;
  try {
    const matchlistMeta = await getData(
      API.getMatchlistMeta(props),
      MatchlistMetaValidator,
      ["deadlock", "matchlistMeta", props.steamId, props.matchId],
      { skipSafetyCheck: true },
    );
    return matchlistMeta;
  } catch (e) {
    devWarn(
      `Deadlock: Failed to fetch matchlist meta for steam id: ${props.steamId}, match id: ${props.matchId}`,
      e,
    );
  }
}
